

<template>
   <div>
   <h3>{{title}}</h3>
    <div class="rating">
    <h6 class="mb-0 mt-20 font-semibold">Precio</h6>
    <h5 class="font-semibold">RD${{price * infoGetted.quantity}}.00</h5>
    <span class=" font-bold ant-tag ant-tag-green">DISPONIBLE</span>
    <h6 class="mt-20 font-semibold">
        <small>Description:</small>
    </h6>
    <p class="mb-5 text-lg text-muted text-justify">{{description}}</p>
    <br>
   <a-row>
        <a-col :md="12" :sm="24">
        <span class="font-semibold">Color:</span>
        <br>
            <a-select v-model="infoGetted.color"  placeholder="Color" style="width: 95%" class="mb-10 mt-10 mr-10" >
                <a-select-option  v-for="(item, index) in colors" :key="item.key" :value="item.value" >
                    {{item.value}}
                </a-select-option>
            </a-select>
        </a-col>
         <a-col  :md="12" :sm="24">
         <span class="font-semibold">Cantidad:</span>
         <br>
            <a-input v-model="infoGetted.quantity"  placeholder="Cantidad" style="width: 95%"  type="number" min="0"  class="mb-10 mt-10" />
        </a-col>
	</a-row>
      <a-row>
        <a-col :md="24" :xl="24" >
            <span class="font-semibold">Nota:</span>
              <a-textarea v-model="infoGetted.note"  placeholder placeholder="Nota" type="text"  class="mt-10" />
             
        </a-col>
	</a-row>
    <br>
    <a-row>
        <a-col :md="4" :xl="4">
            <a-button type="primary" @click="createOrder()">
                CREAR ORDEN
            </a-button>
        </a-col>
    </a-row>
    </div>
   </div>
</template>

<script>
import { getColors } from '@/services/category/colors';

export default ({
props: {
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: "",
        },
        price: {
            type: Number,
            default: 0,
        },
        description: {
            type: String,
            default: "",
        },
    
			
	},
    data() {
        return {
            colors:[],
            infoGetted:{
                color:null,
                note:null,
                quantity:1
            }
       }
    },
    methods:{ 
    async getCategory(){
            let list =  await getColors((list) => {
                this.colors=list
                this.infoGetted.color=this.colors[0].value
            })	
        },
        createOrder(){
            this.$emit('createOrder',this.infoGetted)
            this.infoGetted ={
                color:null,
                note:null,
                quantity:1
            }
        }      
    },
    created(){
        this.getCategory()
      
    }
})
</script>