<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>
    
       <a-breadcrumb class="mb-10">
			<a-breadcrumb-item><router-link to="/products"> Inicio / Productos</router-link></a-breadcrumb-item>
			<a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
		</a-breadcrumb>        
        <a-card :bordered="false" class="header-solid h-full mb-24 card-container" :bodyStyle="{paddingTop: '14px'}">
        <div class="card-title mb-20">
			<h6>Artículo</h6>
		</div>
            <a-row :gutter="[24,24]" align="stretch"type="flex">
                    <a-col :span="24" :md="10" class="mb-24">	
                        <a-card class="card-project">
                            <img
                                slot="cover"
                                alt="example"
                                class="image-container"
                                :src="detail.images[0]"
                            />
                        </a-card>
                        <a-row :gutter="[12,12]"class="mt-10" align="stretch"type="flex">
                            <a-col :md="8" :sm="8" :xs="8" class="mb-24">	
                                <a-card class="card-project">
                                    <img
                                        slot="cover"
                                        alt="example"
                                         class="image-container-sm"
                                        :src="detail.images[1]"
                                    />                       
                                </a-card>
                            </a-col>
                            <a-col :span="24" :md="8"  :sm="8" :xs="8" class="mb-24">	
                                <a-card class="card-project">
                                    <img
                                        slot="cover"
                                        alt="example"
                                         class="image-container-sm"
                                        :src="detail.images[2]"
                                    />
                                </a-card>
                            </a-col>
                            <a-col :span="24" :md="8"  :sm="8" :xs="8" class="mb-24">	
                                <a-card class="card-project">
                                    <img
                                        slot="cover"
                                        alt="example"
                                        class="image-container-sm"
                                        :src="detail.images[3]"
                                    />               
                                </a-card>
                            </a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="24" :md="14" class="mb-24">  
                        <div class="ml-50">                
                          <Description 
                          :title="detail.name"
                          :price="detail.price"
                          :description="detail.description"
                          @createOrder="createOrder"
                          />
                        </div>
                       
                    </a-col>
            </a-row>
   <a-alert v-if="showAlert" message="Orden creada" type="success" show-icon />
	  </a-card>
	</div>
</template>

<script>
import Description from "@/components/commons/Description"
import { getArticleById } from '@/services/products/products';
import { createOrder,getOrderById } from '@/services/orders/orders';
import { isLogin, getUserById} from '@/services/auth/auth';

export default ({
    components:{
        Description
    },
    data() {
        return {
            // Binded model property for "Sign In Form" switch button for "Remember Me" .
            rememberMe: true,
            detail:null,
            userId:null,
            userName:null,
            userAddress:null,
            showAlert:false
        }
    },
    beforeCreate() {
        // Creates the form and adds to it component's "form" property.
        this.form = this.$form.createForm(this, { name: 'normal_login' });
    },
    async created(){

	        await getArticleById((article) => {
			this.detail=article
     
	    	},this.$route.params.id)
			
		},
    methods: {
        // Handles input validation after submission.
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if ( !err ) {
                
                }
            });
        },
        createOrder(val){
            isLogin((user)=>{
				if(user){
                 this.userId=user.uid
                 this.userName=user.displayName.split(",");
				
               
                getUserById((data)=>{
							this.userAddress=data.address
                             this.getData(val)
						},user.uid)

				}else{
                    this.$router.push('/sign-in');
                }	
			})
        },
      async  getData(val){
            let dataTosave={
               address:this.userAddress,
               description:val.note,
               imageOfdescription:this.detail.images[0],
               numberPhone:this.userName[1],
               pricing:this.detail.price * val.quantity,
               productName:this.detail.name,
               quantity:val.quantity,
               storeInfo:this.detail.storeInfo,
               status:"ENVIADA",
               userId:this.userId,
               color:val.color,
               userName:this.userName[0],
               dateCreated:  Date.now()
            }
         this.showAlert=true
         await  createOrder(dataTosave)
         setTimeout(()=>{
         this.showAlert=false
         },3000)
      
        }
    },
})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}

    .gallery-img {
    border-radius: 12px;
    }


    .image-container{
        height: 400px;
        object-fit: cover;
    }
     .image-container-sm{
        height: 150px;
        object-fit: cover;
    }

</style>